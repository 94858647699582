@use 'styles/includes' as *;

.UspList {
    $root: &;
    @extend %container;
    margin: 4rem auto;
    padding: 0;

    &--Large {
        padding: 0 2rem;
    }

    @include media(m) {
        margin: 10rem auto;
        padding: 0 3rem;
    }

    &__List {
        border-top: 0.1rem solid $black;
        border-bottom: 0.1rem solid $black;

        #{$root}--Large & {
            border-right: 0.1rem solid $black;
        }

        @include media(m) {
            border-right: 0.1rem solid $black;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
        }
    }

    &__Item {
        height: auto;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 0;
        box-shadow: -0.1rem 0 0 0 $black;
    }

    &__Pagination {
        padding: 0 2rem;
        display: flex;
        gap: 1rem;
    }

    &__Bullet {
        margin: 2rem 0 0;
        padding: 0;
        width: 100%;
        height: 0.1rem;
        background-color: $grey-75;
        border: 0;
        position: relative;
        cursor: pointer;

        &:focus-visible {
            outline-offset: 0.4rem;
        }

        &::after {
            content: '';
            width: 0;
            height: 0.1rem;
            background-color: $black;
            display: block;
            transition: width 3000ms;
        }

        &--Active {
            &::after {
                animation: fill-animation 3000ms linear forwards;
            }
        }
    }

    &__Link {
        @extend %card-link;
    }

    &__VideoContainer {
        width: 100%;
        position: relative;
        aspect-ratio: 360/380;
        overflow: hidden;

        @include media(m) {
            aspect-ratio: 426/525;
        }
    }

    &__Video {
        aspect-ratio: 9/16;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            inset: 0;
        }
    }

    &__Image {
        width: 100%;
        position: relative;
        aspect-ratio: 360/380;
        z-index: -1;

        @include media(m) {
            aspect-ratio: 426/525;
        }

        #{$root}--Large & {
            max-height: 52.5rem;
        }
    }

    &__Title {
        padding: 4rem 2rem;
        font-family: $font-family-bold;
        font-size: 2rem;
        font-weight: $font-weight-bold;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        border-top: 0.1rem solid $black;
        transition:
            background-color $transition-ease-out-expo,
            color $transition-ease-out-expo;

        @media (hover: hover) {
            #{$root}__Item:hover & {
                background-color: $black;
                color: $white;
            }
        }

        @include media(m) {
            font-size: 3.5rem;
        }
    }
}
